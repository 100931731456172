import { computed, useStore, useRoute } from '@nuxtjs/composition-api';

interface Domain {
  domain: string;
  gtm: null | string;
  indexing: Boolean;
  contacts: any[];
  languages: any[];
  social_links: any[];
  language: any[];
  protocol?: string;
}

export default () => {
  const route = useRoute();
  const store = useStore();
  const domains: Domain[] = store.getters['config/getDomains'];

  const alternateLinks = computed(() => {
    return domains?.map((domain) => {
      const domainProtocol = domain.protocol ?? 'https';
      return {
        rel: 'alternate',
        href: `${domainProtocol}://${domain.domain}${route.value.path}`,
        hreflang: domain.language.find((lang) => lang.default === true)?.code,
      };
    });
  });

  return {
    alternateLinks,
  };
};
