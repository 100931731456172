import Vue from 'vue';
import { abPlugin } from '@webplatform/asbis-ui';
import { forEachSections } from '@webplatform/asbis-ui/dist/sections';
import '~/assets/scss/main.scss';

forEachSections((sectionName, sectionComponent) => {
  if (sectionName !== 'HeroSection')
    Vue.component(sectionName, sectionComponent);
});

// eslint-disable-next-line func-names
export default function ({ $gsap, $ScrollTrigger }) {
  Vue.use(abPlugin, {
    preset: 'lorgar',
    components: {
      AbButton: {
        props: {
          effect: 'glow',
        },
      },
    },
    gsap: $gsap,
    ScrollTrigger: $ScrollTrigger,
  });
}
