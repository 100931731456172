
import {
  defineComponent,
  useFetch,
  ref,
  useMeta,
} from '@nuxtjs/composition-api';
import usePage from '@/composables/usePage';
import ServiceFormSection from '~/components/sections/ServiceFormSection.vue';
import useAlternateLinks from '~/composables/useAlternateLinks';

export default defineComponent({
  components: {
    ServiceFormSection,
  },
  head: {},
  setup() {
    const isLoading = ref(false);
    const isLoaded = ref(false);
    const { page, fetchPage } = usePage('main');
    const { alternateLinks } = useAlternateLinks();

    useFetch(async () => {
      isLoading.value = true;
      await fetchPage();
      isLoaded.value = true;

      if (process.client) {
        setTimeout(() => {
          isLoading.value = false;
        }, 50);
      } else {
        isLoading.value = false;
      }
    });
    useMeta(() => ({
      link: alternateLinks.value,
    }));

    return {
      page,
      isLoading,
      isLoaded,
    };
  },
});
