import { computed, useContext } from '@nuxtjs/composition-api';
import { CdnImg } from '@webplatform/cdn-image';


export default {
  __name: 'AppLogo',
  setup(__props) {

const { store } = useContext();

const apiLogo = store.getters['config/getLogotypes'];

const logotype = computed(() => apiLogo.desktop ?? apiLogo.mobile ?? 'default');

return { __sfc: true,store, apiLogo, logotype, CdnImg }
}

}