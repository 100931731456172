
import { computed, defineComponent } from '@nuxtjs/composition-api';

const bgColors = {
  'gray-dark': 'bg-gray-dark',
  blue: 'bg-blue',
  'gradient-red': 'bg-gradient-red-pink',
  pink: 'bg-pink',
  transparent: 'bg-transparent',
};

const bgHoverColors = {
  'gray-dark': 'group-hover/card:bg-gray-dark group-hover/card:bg-none',
  blue: 'group-hover/card:bg-blue group-hover/card:bg-none',
  'gradient-red': 'group-hover/card:bg-gradient-red-pink',
  pink: 'group-hover/card:bg-pink group-hover/card:bg-none',
  transparent: 'group-hover/card:bg-transparent group-hover/card:bg-none',
};

export default defineComponent({
  props: {
    bg: {
      type: String,
      default: 'bg-bg-primary',
    },
    borderColor: {
      type: String,
      default: 'gray-dark',
    },
    borderHoverColor: {
      type: String,
      default: '',
    },
    hovered: {
      type: Boolean,
      default: true,
    },
    hoveredBorderAnimation: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const bgColor = computed(() => {
      return bgColors[props.borderColor];
    });

    const bgHoverColor = computed(() => {
      if (!props.borderHoverColor) {
        return '';
      }

      return bgHoverColors[props.borderHoverColor];
    });

    return {
      bgColor,
      bgHoverColor,
    };
  },
});
