import { render, staticRenderFns } from "./AppLogo.vue?vue&type=template&id=6bc3aaad"
import script from "./AppLogo.vue?vue&type=script&setup=true&lang=js"
export * from "./AppLogo.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLogoIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/AppLogoIcon.vue').default})
